
    
<template>
  <div>
    <CModal :title="'Tracking Usulan ' + items.modalTitle " :show.sync="modalViewTracking" size="lg">
      <CSpinner color="success" size="lg" style="position: absolute; top: 30%; left: 50%;" v-if="loading" />
      <div v-if="loading"><p></p><br></div>
      <ul class="timeline" v-show="trackings.length > 0" v-else>
        <li class="timeline-item">
          <div class="timeline-badge success">
            <CIcon name="cilLightbulb" />
          </div>
          <div class="timeline-panel">
            <div class="timeline-heading">
              <h4 class="timeline-title">Mulai</h4>
            </div>
          </div>
        </li>
        <li class="timeline-item" v-for="(tracking, index) in trackings" :key="index">
          <div class="timeline-badge primary">
            <CIcon name="cilLightbulb" />
          </div>
          <div class="timeline-panel">
            <div class="timeline-heading">
              <h4 class="timeline-title">{{ tracking.title }}</h4>
              <div class="timeline-panel-controls">
                <div class="timestamp">
                  <small class="text-muted">{{ tracking.created }}</small>
                </div>
              </div>
            </div>
            <!-- TODO, remove v-show tag if necessary -->
            <!-- <div class="timeline-body" v-html="tracking.body" v-show="false"></div> -->
            <div class="timeline-body" v-html="tracking.body"></div>
          </div>
        </li>
        <li class="timeline-item" v-show="lastRow != null && lastRow.status == 5">
          <div class="timeline-badge danger">
            <CIcon name="cilLightbulb" />
          </div>
          <div class="timeline-panel">
            <div class="timeline-heading">
              <h4 class="timeline-title">Selesai</h4>
            </div>
          </div>
        </li>
      </ul>
      <template #footer>
        <CButton color="primary" @click="() => { modalViewTracking = false }">
          Tutup
        </CButton>
      </template>
    </CModal>
  </div>
</template>
<script>
export default {
  name: "ModalViewTracking",
  data() {
    return {
      modalViewTracking: false,
      loading: false,
      items: [],
      lastRow: {
        status: null
      },
      trackings: [
        {
          id: 5,
          title: 'Admin added a comment.',
          created: '24. Sep 17:03',
          body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Totam, maxime alias nam dignissimos natus.'
        },
        {
          id: 4,
          title: 'Started editing',
          created: '24. Sep 14:48',
          body: 'Someone has started editing.'
        },
        {
          id: 3,
          title: 'Message delegated',
          created: '23. Sep 11:12',
          body: 'This message has been delegated.'
        },
        {
          id: 2,
          title: 'Message approved and forwarded',
          created: '20. Sep 15:56',
          body: 'Message has been approved and forwarded to responsible.'
        },
        {
          id: 1,
          title: 'Message forwarded for approval',
          created: '19. Sep 19:49',
          body: 'Message has been forwarded for approval.'
        },
      ]
    };
  },
  computed: {
  },
  methods: {
    toggle(item) {
      this.items = [];
      this.items.modalTitle = '';
      this.modalViewTracking = !this.modalViewTracking;
      this.loadData(item);
    },
    async loadData(item) {
      try {
        this.loading = true;
        let { data } = await this.$store.dispatch("pelayanan/trackingPelayanan", {
          id: item.id,
        });
        // this.items = data;
        this.items.modalTitle = item.modalTitle
        this.trackings = data.data
        this.lastRow = data.lastRow
      } catch (ex) {
        this.items = [];
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>